<template>
  <div>
    <a-modal
      width="800px"
      v-model="isShow"
      centered
      :mask-closable="false"
      title="套餐详情"
    >
      <template slot="footer">
        <a-button class="ant-btn-primary" @click="closeModal">
          关闭
        </a-button>
      </template>

      <a-spin :spinning="spinning">
        <a-row>
          <a-col :span="12">
            <a-descriptions
              title="基本信息"
              :column="1"
              class="iotplt-descriptions iotplt-descriptions-max-6"
            >
              <a-descriptions-item label="套餐名称">
                {{ data.name }}
              </a-descriptions-item>

              <a-descriptions-item label="运营商种类">
                {{ data.carrier_type }}
              </a-descriptions-item>

              <a-descriptions-item label="用户业务模式">
                {{ data.user_business_type }}
              </a-descriptions-item>

              <a-descriptions-item label="是否可超池">
                {{ data.is_allow_exceed_pool }}
              </a-descriptions-item>

              <a-descriptions-item label="套餐周期">
                {{ data.service_cycle }}
              </a-descriptions-item>

              <a-descriptions-item label="套餐容量">
                {{ data.package_capacity }}
              </a-descriptions-item>

              <a-descriptions-item label="语音">
                {{ data.voice_capacity }}
              </a-descriptions-item>

              <a-descriptions-item label="套餐描述">
                {{ data.description }}
              </a-descriptions-item>

              <a-descriptions-item label="单卡可订购周期数(个)" v-if="isCustomDay">
                {{ data.limit_subscribe_cycle }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>

          <a-col :span="12">
            <a-descriptions title="价格设置" :column="favorablePriceColumnSize">
              <a-descriptions-item>
                周期数
              </a-descriptions-item>

              <a-descriptions-item>
                套餐价格(元)
              </a-descriptions-item>

              <a-descriptions-item v-if="data.is_allow_retail && agentType === 1">
                零售价(元)
              </a-descriptions-item>
            </a-descriptions>

            <a-descriptions
              :column="favorablePriceColumnSize"
              v-for="favorablePrice in data.agents_product_favorable_prices"
              :key="favorablePrice.cycles"
            >
              <a-descriptions-item>
                {{ favorablePrice.cycles }}
              </a-descriptions-item>

              <a-descriptions-item>
                {{ favorablePrice.distributor_price | formatFloat }}
              </a-descriptions-item>

              <a-descriptions-item v-if="data.is_allow_retail">
                {{ favorablePrice.retail_price | formatFloat }}
              </a-descriptions-item>
            </a-descriptions>

            <a-descriptions
              :column="2"
              v-if="!isCustomDay && !data.is_allow_retail"
            >
              <a-descriptions-item>
                超额计费单位
              </a-descriptions-item>

              <a-descriptions-item>
                价格(元)
              </a-descriptions-item>

              <a-descriptions-item>
                {{ data.excess_rate_quantity }}
              </a-descriptions-item>

              <a-descriptions-item>
                {{ data.excess_distributor_price | formatFloat }}
              </a-descriptions-item>
            </a-descriptions>

            <a-descriptions
              :column="1"
              v-if="this.userBusinessType === 'custom_month' || this.userBusinessType === 'traffic_share'"
            >
              <a-descriptions-item>
                停机保号价格(元)
              </a-descriptions-item>

              <a-descriptions-item>
                {{ data.stop_distributor_price | formatFloat }}
              </a-descriptions-item>
            </a-descriptions>

            <a-descriptions title="卡片设置" :column="2">
              <a-descriptions-item>
                卡片材质
              </a-descriptions-item>

              <a-descriptions-item>
                卡板价格(元)
              </a-descriptions-item>
            </a-descriptions>

            <a-descriptions
              :column="2"
              v-for="cardType in data.agents_products_card_types"
              :key="cardType.id"
            >
              <a-descriptions-item>
                {{ cardType.card_type_name }}
              </a-descriptions-item>

              <a-descriptions-item>
                {{ cardType.price | formatFloat }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
        </a-row>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { findAgentsProduct } from '@/api/agents_product'

export default {
  name: 'ShowAgentsProduct',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      data: {},
      userBusinessType: '',
      favorablePriceColumnSize: 2,
      isCustomDay: false,
      spinning: true
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    agentType() {
      return parseInt(this.$store.getters.userAgentType)
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.spinning = true
      findAgentsProduct(this.id).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.userBusinessType = this.data.user_business_type_slug
          if (this.userBusinessType === 'custom_day') {
            this.isCustomDay = true
          }
          if (this.data.is_allow_retail) {
            this.favorablePriceColumnSize = 3
          }
        }

        this.spinning = false
      })
    },

    closeModal() {
      this.isShow = false
    }
  }
}
</script>
